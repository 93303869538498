import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initInlineSvg } from "@/core/plugins/inline-svg";
// import MockAdapter from "@/core/mock/MockService";
import ApiService from "@/core/services/ApiService";

import "@/core/plugins/keenthemes.ts";
import "popper.js";
import { initApexCharts } from "@/core/plugins/apexcharts";
import ElementPlus from "element-plus";
import "bootstrap";
import "@/core/plugins/prismjs.ts";

const app = createApp(App);
ApiService.init(app);
initInlineSvg(app);
initApexCharts(app);
// MockAdapter.init(app);
app.use(store);
app.use(router);
app.use(() => ElementPlus);
app.mount("#app");
