import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

// Redirect to sign-in page if not yet authenticated
const checkAuthentication = (to, from, next) => {
  const { isAuthenticated, user } = store.state.AuthModule;
  // if(isAuthenticated) {
  //   return next()
  // } else {
  //   next({ name: "sign-in" })
  // }
  return isAuthenticated ? next() : next({ name: "sign-in" });
};

// Redirect to dashboard page if is already authenticated
const checkIfAlreadyAuthenticated = (to, from, next) => {
  const { isAuthenticated, user } = store.state.AuthModule;
  return isAuthenticated
    ? next({
        name: user.role === "member" ? "member" : "users",
      })
    : next();
};

const checkAuthenticatedUser = () => {
  const { isAuthenticated, user } = store.state.AuthModule;
  if (user && (user.role === "manager" || user.role === "admin"))
    return "/users";
  return "/member";
};

const validateRoutes = async (next, roles) => {
  const { isAuthenticated, user } = store.state.AuthModule;
  if (!isAuthenticated || !user) {
    return next({ name: "404" });
  }

  if (isAuthenticated && user.role && !roles.includes(user.role)) {
    return next({ name: "404" });
  }

  return next();
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: checkAuthenticatedUser,
    component: () => import("@/layout/Layout.vue"),
    beforeEnter: checkAuthentication,
    children: [
      {
        path: "/view-users-list",
        name: "view-users-list",
        component: () => import("@/views/account/ViewUsersList.vue"),
        beforeEnter: async (to, from, next) => {
          await validateRoutes(next, ["admin"]);
        }
      },
      {
        path: "/dashboard",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        children: [
          {
            path: "overview",
            alias: "",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue"),
          },
          {
            path: "members",
            name: "members",
            component: () => import("@/views/account/Members.vue"),
          },
          {
            path: "email-look-up",
            name: "email-look-up",
            component: () => import("@/views/account/EmailLookUp.vue"),
          },
        ],
      },
      {
        path: "/my-settings",
        name: "my-settings",
        component: () => import("@/views/account/Settings.vue"),
      },
      {
        path: "/member",
        name: "member-overview",
        component: () => import("@/views/account/Member.vue"),
      },
      {
        path: "/admin-overview",
        name: "admin-overview",
        component: () => import("@/views/account/Admin.vue"),
      },
      {
        path: "/worker-manager",
        name: "worker-manager",
        component: () => import("@/views/worker_manager/Manager.vue"),
        beforeEnter: async (to, from, next) => {
          await validateRoutes(next, ["admin"]);
        },
      },
      {
        path: "/admin-invite",
        name: "admin-invite",
        component: () => import("@/views/account/AdminInvite.vue"),
        beforeEnter: async (to, from, next) => {
          await validateRoutes(next, ["admin"]);
        }
      },
      {
        path: "/config-settings",
        name: "config-settings",
        component: () => import("@/views/account/ConfigSettings.vue"),
        beforeEnter: async (to, from, next) => {
          await validateRoutes(next, ["admin"]);
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/account/Users.vue"),
        beforeEnter: async (to, from, next) => {
          await validateRoutes(next, ["admin", "manager"]);
        },
      },
      {
        path: "/accounts",
        name: "accounts",
        component: () => import("@/views/account/Accounts.vue"),
        beforeEnter: async (to, from, next) => {
          await validateRoutes(next, ["admin"]);
        },
      },
      {
        path: "/integrations",
        name: "integrations",
        component: () => import("@/views/account/Integrations.vue"),
      },
    ],
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
    beforeEnter: checkIfAlreadyAuthenticated
  },
  {
    path: "/sign-up/",
    name: "sign-up",
    component: () => import("@/views/auth/SignUpV3.vue"),
    beforeEnter: checkIfAlreadyAuthenticated
  },
  {
    path: "/sign-up/:referral_code",
    name: "sign-up-referral-code",
    component: () => import("@/views/auth/SignUpV3.vue"),
    beforeEnter: checkIfAlreadyAuthenticated
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.AuthModule;
      if (isAuthenticated) {
        store.dispatch(Actions.LOGOUT);
      }
      return next();
    },
  },
  {
    path: "/set-password",
    name: "set-password",
    component: () => import("@/views/auth/SetPassword.vue"),
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.AuthModule;
      // if (isAuthenticated && to.query["action"] == "change") {
      //   store.dispatch(Actions.LOGOUT);
      // }
      return next();
    },
  },
  {
    path: "/manager-invite",
    name: "manager-invite",
    component: () => import("@/views/auth/ManagerInvite.vue"),
    beforeEnter: (to, from, next) => {
      const { isAuthenticated } = store.state.AuthModule;

      if (isAuthenticated) {
        store.dispatch(Actions.LOGOUT);
      }

      return next();
    },
  },
  {
    path: "/admin-registration",
    name: "admin-registration",
    component: () => import("@/views/auth/AdminRegistration.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
