import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

@Module
export default class BillingSeatsModule extends VuexModule {
  seatsQty = 0;
  subTotal = 0;
  paymentMethod = {};

  get getPaymentMethod() {
    return this.paymentMethod;
  }

  get currentQty(): number {
    return this.seatsQty;
  }

  get currentSubTotal(): number {
    return this.subTotal;
  }

  @Mutation
  [Mutations.SET_QTY](qty) {
    this.seatsQty = qty;
  }

  @Mutation
  [Mutations.SET_SUBTOTAL](subtotal) {
    this.subTotal = subtotal;
  }

  @Mutation
  [Mutations.SET_PAYMENT_METHOD](pm) {
    this.paymentMethod = pm;
  }

  @Action
  [Actions.INCREASE_QTY]() {
    this.context.commit(Mutations.SET_QTY, this.seatsQty + 1);
  }

  @Action
  [Actions.DECREASE_QTY]() {
    this.context.commit(Mutations.SET_QTY, this.seatsQty - 1);
  }

  @Action
  [Actions.UPDATE_SUBTOTAL](subtotal) {
    this.context.commit(Mutations.SET_SUBTOTAL, subtotal);
  }

  @Action
  [Actions.INIT_QTY](qty) {
    this.context.commit(Mutations.SET_QTY, qty);
  }

  @Action
  [Actions.UPDATE_PAYMENT_METHOD]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/v1/subscription/payment-method")
        .then(res => {
          const { data } = res;
          this.context.commit(Mutations.SET_PAYMENT_METHOD, data.data);
          resolve();
        })
        .catch(e => {
          console.log("error on getting payment method", e);
          reject();
        });
    });
  }
}
