const ID_TOKEN_KEY = "id_token" as string;
const IMPERSONATE_TOKEN = "impersonate_token" as string;
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(IMPERSONATE_TOKEN) === null
    ? window.localStorage.getItem(ID_TOKEN_KEY)
    : window.localStorage.getItem(IMPERSONATE_TOKEN);
};

export const getImpersonator = (): string | null => {
  return window.localStorage.getItem(IMPERSONATE_TOKEN);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  const key =
    window.localStorage.getItem(IMPERSONATE_TOKEN) === null
      ? ID_TOKEN_KEY
      : IMPERSONATE_TOKEN;
  window.localStorage.setItem(key, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(IMPERSONATE_TOKEN);
};

export const saveTokenByKey = (key: string, value: string): void => {
  window.localStorage.setItem(key, value);
};

export const destroyTokenByKey = (key): void => {
  window.localStorage.removeItem(key);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  getImpersonator,
  saveTokenByKey,
  destroyTokenByKey
};
