import ApiService from "@/core/services/ApiService";
import JwtService, { getImpersonator } from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  accessToken: string;
  role: string;
  status: string;
  stripe: {
    customerId: string;
    paymentMethod: object;
    subscription: object;
  };
  ats: object;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  currentRole = undefined;
  isAuthenticated = !!JwtService.getToken();
  _members = [];
  _JAmembers = [];
  isImpersonating = !!JwtService.getImpersonator();
  impersonateToken = JwtService.getImpersonator();
  showLoaderModal = false;

  get loggedInAs(): boolean {
    return this.isImpersonating;
  }

  get getImpersonateToken(): string | null {
    return this.impersonateToken;
  }

  get getShowLoaderModal(): boolean {
    return this.showLoaderModal;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get current user ATS object
   * @returns User
   */
  get currentUserAts() {
    const code =
      Object.keys(this.user.ats).find((ats: any) => this.user.ats[ats].inUse) ||
      "jobadder";
    const index = Object.keys(this.user.ats).findIndex(
      (ats: any) => this.user.ats[ats].inUse
    );

    let title;
    switch (code) {
      case "jobadder":
        title = "JobAdder";
        break;
      case "bullhorn":
        title = "Bullhorn";
        break;
      case "vincere":
        title = "Vincere";
        break;
    }

    return { code, title, index: index < 0 ? 0 : index };
  }

  /**
   * Get current user object
   * @returns User
   */
  get getCurrentRole() {
    return this.currentRole;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /**
   * Get members
   */
  get members() {
    return this._members;
  }

  get JAmembers() {
    return this._JAmembers;
  }

  @Mutation
  [Mutations.SET_TOKEN](obj) {
    if (obj.key === "impersonate_token") {
      this.impersonateToken = obj.value;
    }
    JwtService.saveTokenByKey("impersonate_token", obj.value);
  }

  @Mutation
  [Mutations.REMOVE_TOKEN](key) {
    if (key === "impersonate_token") {
      this.impersonateToken = null;
    }

    JwtService.destroyTokenByKey("impersonate_token");
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.currentRole = user.role;
    this.errors = [];
    JwtService.saveToken(this.user.accessToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  membersList(list) {
    this._members = list || [];
  }

  @Mutation
  JAmembersList(list) {
    this._JAmembers = list || [];
  }

  @Mutation
  [Mutations.HANDLE_LOADERMODAL](val: boolean) {
    this.showLoaderModal = val;
  }

  @Action
  [Actions.FORCE_IMPERSONATE_USER](obj) {
    const objToken = {
      key: "impersonate_token",
      value: obj.accessToken
    };

    this.context.commit(Mutations.SET_TOKEN, objToken);
  }

  @Action
  [Actions.SHOWLOADER_MODAL](val: boolean) {
    this.context.commit(Mutations.HANDLE_LOADERMODAL, val);
  }

  @Action
  [Actions.UPDATE_TOKEN](keyval: object) {
    this.context.commit(Mutations.SET_TOKEN, keyval);
  }

  @Action
  [Actions.DESTROY_TOKEN](key) {
    this.context.commit(Mutations.REMOVE_TOKEN, key);
  }

  @Action
  [Actions.IMPERSONATE_USER](userData) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/v1/user/impersonate", userData)
        .then(({ data }) => {
          const userData = data?.data;
          if (data.success) {
            const objToken = {
              key: "impersonate_token",
              value: userData.accessToken
            };
            this.context.commit(Mutations.SET_TOKEN, objToken);
            //window.localStorage.setItem(
            //  "impersonate_token",
            //  userData.accessToken
            //);
            resolve();
          } else {
            this.context.commit(Mutations.SET_ERROR, [
              data.message || "Unknown API error."
            ]);
          }
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, ["Server error occured."]);
          } else {
            if (response.data.error) {
              this.context.commit(Mutations.SET_ERROR, response.data);
            } else {
              this.context.commit(Mutations.SET_ERROR, [
                response.data.message || "Unknown API error."
              ]);
            }
          }

          reject();
        });
    });
  }

  @Action
  [Actions.GOOGLE_LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/v1/user/google-login", credentials)
        .then(({ data }) => {
          const userData = data?.data;
          this.context.commit(Mutations.SET_AUTH, userData);
          resolve();
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, ["Server error occured."]);
          } else {
            if (response.data.error) {
              this.context.commit(Mutations.SET_ERROR, response.data);
            } else {
              this.context.commit(Mutations.SET_ERROR, [
                response.data.message || "Unknown API error."
              ]);
            }
          }

          reject();
        });
    });
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/v1/user/login?role=manager", credentials)
        .then(({ data }) => {
          const userData = data?.data;
          this.context.commit(Mutations.SET_AUTH, userData);
          resolve();
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, ["Server error occured."]);
          } else {
            if (response.data.error) {
              this.context.commit(Mutations.SET_ERROR, response.data);
            } else {
              this.context.commit(Mutations.SET_ERROR, [
                response.data.message || "Unknown API error."
              ]);
            }
          }

          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/v1/user")
        .then(({ data }) => {
          data.data.accessToken = JwtService.getToken();
          this.context.commit(Mutations.SET_AUTH, data.data);
        })
        .catch(({ response }) => {
          console.log(response);
          this.context.commit(Mutations.PURGE_AUTH);
          router.push({ name: "sign-in" });
          Swal.fire({
            text: response.data.message || "Unknown API error.",
            icon: "error"
          });
          this.context.commit(Mutations.SET_ERROR, [
            response.data.message || "Unknown API error."
          ]);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
