enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  INCREASE_QTY = "increaseQty",
  DECREASE_QTY = "decreaseQty",
  UPDATE_SUBTOTAL = "updateSubtotal",
  INIT_QTY = "initQuantity",
  UPDATE_PAYMENT_METHOD = "updatePaymentMethod",
  IMPERSONATE_USER = "impersonateUser",
  UPDATE_TOKEN = "updateToken",
  DESTROY_TOKEN = "destroyToken",
  SHOWLOADER_MODAL = "setLoaderModal",
  GOOGLE_LOGIN = "googleLogin",
  FORCE_IMPERSONATE_USER = "setForceImpersonateUser"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_QTY = "setQty",
  SET_SUBTOTAL = "setSubtotal",
  SET_PAYMENT_METHOD = "setPaymentMethod",
  SET_TOKEN = "setToken",
  REMOVE_TOKEN = "removeToken",
  HANDLE_LOADERMODAL = "handleLoaderModal"
}

export { Actions, Mutations };
